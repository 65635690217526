<script>
  import { onMount } from "svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import Heading1 from "components/elements/aliases/Heading1.svelte";
  import Btn from "components/elements/Btn.svelte";
  import { getBranchData } from "./modules/Branch.svelte";
  import { APP_URL } from "utils/menu.js";

  let referralCode = "";
  let branchData = null;

  async function initCachedBranch() {
    try {
      branchData = await getBranchData();
    } catch (e) {
      branchData = null;
      console.log("Branch retrieval failed: ", e);
    }
  }

  function getAppUrl(referral) {
    return `${APP_URL}${referral ? `&referral=${referral}` : ""}`;
  }

  function resetForm() {
    referralCode = "";
  }

  async function handleSubmit() {
    const url = getAppUrl(referralCode);
    // TODO: validate referral code
    resetForm();
    window.location = url;
  }

  onMount(() => initCachedBranch());
</script>

<PageSection heroPurple>
  <div class="login-form">
    <div class="columns">
      <div class="column text-column">
        <Heading1 white
          ><span class="header-title"
            >Open your Liquid Noble Tokenized Wallet Now</span
          >
        </Heading1>
        <div class="subtitle">
          Sign into your Liquid Noble account by opening a Tokenized wallet.
          Registering for a Tokenized wallet automatically creates a Liquid
          Noble account.
        </div>
        <form on:submit|preventDefault={handleSubmit}>
          <div>
            <Body class="referral-text">
              If you have a referral code provided by an affiliate, please enter
              it below before signing in.
            </Body>
            <div class="button-row">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Referral code (optional)"
                  maxlength="64"
                  bind:value={referralCode}
                />
              </div>
              <Btn buttonType="submit" class="tokenized-button" gold wide>
                Register for Liquid Noble
              </Btn>
            </div>
          </div>
        </form>
        <span class="toc-row">
          By signing in or creating an account, you agree to the
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/terms-and-conditions"
          >
            Terms & Conditions,
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/privacy-policy"
          >
            Privacy Policy,
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/aml-ctf-policy"
          >
            AML/CTF Policy
          </a>
          and
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/cookie-policy"
          >
            Cookie Policy
          </a>
        </span>
        <div class="mobile">
          <img src="../../images/dashboard_gold.webp" class="dashboard-image" />
        </div>
      </div>
      <div class="column image-column desktop">
        <img src="../../images/dashboard_gold.webp" class="dashboard-image" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .login-form {
    @extend .generic-section;
    max-width: $size-laptop-max;
    gap: 128px;
    min-height: 60vh;
    margin-bottom: 90px;
    .columns {
      position: relative;
      justify-content: center;
      column-gap: 52px;
      margin: 0px;
      padding: 0px 48px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    .toc-row {
      font-size: 12px;
      a {
        font-size: 12px;
        color: var(--color-P40);
      }
    }
    .highlighted-text {
      color: #d9b44f;
    }
    .subtitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      max-width: 537px;

      @media only screen and (max-width: $size-phablet-min) {
        font-size: 18px;
        line-height: 32px;
      }
    }
    .header-title {
      @media only screen and (max-width: $size-phablet-min) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    .column {
      gap: 24px;
      z-index: 1;
      &.text-column {
        width: 664px;
        max-width: 664px;
        padding-top: 192px;
        margin-left: 48px;

        @media only screen and (max-width: $size-phablet-min) {
          margin-left: 0px;
        }

        @media only screen and (max-width: $size-phablet-min) {
          padding-top: 124px;
        }
      }
      &.image-column {
        padding-top: 198px;
        width: 534px;
        height: 100%;
        padding-bottom: 24px;
        img {
          margin-left: 40px;
        }
      }
      .dashboard-image {
        width: inherit;
      }
      .mobile {
        display: none;
        .dashboard-image {
          width: 100%;
          margin-bottom: 24px;
          max-width: 320px;
        }
      }
      .image {
        width: 314px;
      }
    }
    .buttons {
      width: 100%;
      column-gap: 24px;
      padding-bottom: 24px;
      max-width: 585px;
      overflow: visible;
      flex-wrap: nowrap;

      @media only screen and (max-width: $size-tablet-min) {
        flex-wrap: wrap;
      }

      span {
        display: flex;
        gap: 12px;
      }
      .hero-input {
        width: 180px;
        height: 36px;
        border-radius: 4px;
        background-color: white;
        font-family: Heebo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        padding: 3px 10px;
        border: none;
        &::placeholder {
          color: var(--color-N50);
        }
      }
    }
    .hero-input + .hero-input {
      width: 242px;
    }
    @media only screen and (min-width: $size-phablet-min) and (max-width: $size-tablet-max) {
      .columns {
        text-align: center;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .mobile {
          display: block;
        }
        .desktop {
          display: none;
        }
        .image {
          margin-top: 48px;
          width: 260px;
        }
      }
    }
    @media only screen and (max-width: $size-phablet-min) {
      gap: 80px;

      .columns {
        flex-direction: column-reverse;
        text-align: center;
        .column {
          width: 100%;
          gap: 24px;
          &.desktop {
            display: none;
          }
          .image {
            margin-top: 48px;
            width: 180px;
          }
          .mobile {
            display: block;
          }
        }
        .buttons {
          gap: 12px;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          span {
            flex-direction: column;
          }

          .hero-input {
            width: 180px;
          }
        }
      }
    }

    :global(.referral-text) {
      margin-bottom: 4px;
      font-size: 13px;
      color: var(--color-white);
      @media only screen and (max-width: $size-phablet-min) {
        line-height: 18px;
      }
    }

    :global(.tokenized-button) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;
      height: 48px;
      font-size: 16px !important;

      line-height: 18px;
      white-space: nowrap;

      border-radius: 6px;
      color: var(--color-white);

      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border: 0 solid transparent;
      box-shadow: none;

      &:hover {
        filter: brightness(110%) !important;
      }
      &:active {
        filter: brightness(110%) !important;
      }
    }
    .tokenized-logo {
      height: 24px;
      width: 24px;
      img {
        border-radius: 4px;
      }
    }
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: none;
      // border-bottom: 1px solid #abb1b8;
      background-color: transparent;
      resize: none;
    }

    .button-row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 8px;

      @media only screen and (max-width: $size-tablet-max) {
        justify-content: center;
      }

      @media only screen and (max-width: $size-phablet-min) {
        flex-wrap: wrap;
      }
    }
    .form-group {
      width: 100%;
      max-width: 210px;
      height: 48px;
      background: #fff;
      border-radius: 4px;
      overflow: hidden;
      border: 0.5px var(--color-N40) solid;
      color: var(--color-N80);
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      display: flex;
      input[type="text"] {
        width: 100%;
        padding: 8px;
        font-size: 15px;
        border: 0;
        background-color: white;
        resize: none;
      }
    }
  }
</style>
